import TeamCard from "./TeamCard";
import teamData from "./team";
import styles from "./Team.module.css";

const TeamSection = () => {
  return (
    <section className="container d-flex flex-column gap-3 text-center mb-5">
      <header className="d-flex flex-column align-items-center gap-2 mb-3">
        <h1 className="text-center">Our Team</h1>
        <h2 className="text-center">Introducing Our Dedicated Team</h2>
      </header>
      <div
        className={`${styles.membersWrapper} d-flex justify-content-center align-items-center`}
      >
        {teamData.map((member, idx) => (
          <TeamCard key={idx} {...member} />
        ))}
      </div>
    </section>
  );
};

export default TeamSection;
