const loadCashfree = () => {
  const cashfreeScript = document.createElement("script");
  cashfreeScript.src = "https://sdk.cashfree.com/js/v3/cashfree.js";
  cashfreeScript.onerror = () => {
    console.error("Cashfree SDK failed to load. Are you online?");
  };
  document.body.append(cashfreeScript);
};

const getCashfree = () => {
  return window.Cashfree({ mode: "production" });
};

export { loadCashfree, getCashfree };
