const teamData = [
  {
    name: "Ayush Saxena",
    role: "Founder",
    intro: "Ayush Saxena: The Mastermind of Notes-Era",
    img_link: "Assets2/Ayush_Saxena.jpg",
    highlights: ["Originator", "Optimism", "Visionary"],
    linkedin:
      "https://www.linkedin.com/in/ayush-saxena-3684b2218?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    instagram:
      "https://www.instagram.com/ayushsaxena_29/profilecard/?igsh=MWEwM29tNGg1eWoyeA==",
  },
  {
    name: "Sanskar Soni",
    role: "Senior Developer",
    intro: "Sanskar Soni: Architect of seamless systems",
    img_link: "Assets2/Sanskar_Soni.jpg",
    highlights: ["Technology", "Development", "Liberated"],
    linkedin: "https://www.linkedin.com/in/sanskarsoni9/",
    instagram: null,
  },
];

export default teamData;
