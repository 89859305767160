import styles from "./Team.module.css";

const TeamCard = ({
  name,
  role,
  intro,
  img_link,
  highlights,
  linkedin,
  instagram,
}) => {
  return (
    <div className={`${styles.memberWrapper} col col-lg-5 text-start p-s-xs`}>
      <div className="course_card">
        <div className="item_image">
          <a href={instagram || linkedin} target="_blank" rel="noreferrer">
            <img src={img_link} alt={name} style={{ height: "310px" }} />
          </a>
        </div>
        <div className={`${styles.memberContent} item_content`}>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <ul className="item_category_list unordered_list">
              <li>
                <a href="#!">{role}</a>
              </li>
            </ul>
          </div>
          <ul className="meta_info_list unordered_list">
            {highlights[0] ? (
              <li>
                <i className="fas fa-chart-bar"></i>
                <span>{highlights[0]}</span>
              </li>
            ) : (
              ""
            )}
            {highlights[1] ? (
              <li>
                <i className="fas fa-clock"></i>
                <span>{highlights[1]}</span>
              </li>
            ) : (
              ""
            )}
            {highlights[2] ? (
              <li>
                <i className="fas fa-star"></i>
                <span>{highlights[2]}</span>
              </li>
            ) : (
              ""
            )}
          </ul>
          <h3 className="item_title">
            <a href={instagram || linkedin} target="_blank" rel="noreferrer">
              {intro}
            </a>
          </h3>
          <div className="d-flex gap-3 flex-nowrap">
            <a
              className="btn_unfill"
              href={linkedin}
              target="_blank"
              rel="noreferrer"
            >
              <span className="btn_text">LinkedIn</span>
              <span className="btn_icon">
                <i className="fas fa-long-arrow-right"></i>
                <i className="fas fa-long-arrow-right"></i>
              </span>
            </a>
            {instagram ? (
              <a
                className="btn_unfill"
                href={instagram}
                target="_blank"
                rel="noreferrer"
              >
                <span className="btn_text">Instagram</span>
                <span className="btn_icon">
                  <i className="fas fa-long-arrow-right"></i>
                  <i className="fas fa-long-arrow-right"></i>
                </span>
              </a>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
